import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4fdc2585"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=4fdc2585&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InfoButtons: require('/opt/razlet.kg/src/components/info-buttons.vue').default,BannersSlider: require('/opt/razlet.kg/src/components/banners-slider.vue').default,NewsBlock: require('/opt/razlet.kg/src/components/news/block.vue').default,RzContainer: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/container/index.vue').default})
